import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import FilmGallery from './component/FilmGallery'; 
import FilmDetails from './component/FilmDetails'; 
import "./App.css"
import Header from './component/Header';
import Footer from './component/Footer';
import PageNotFound from './component/PageNotFound';
import Contact from './component/Contact';
import Cv from './component/Cv';
import Demo from './component/Demo';

const App = () => {
  return (
    <div className='app'>
    <Router>
    <Header />
    <main className='container'>
    <Routes>
        <Route exact path="/" element={<FilmGallery />} />
        <Route path='/bande-demo' element={<Demo />} />
        <Route path="/realisation/:id" element={<FilmDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/a-propos" element={<Cv />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
      <Footer />
    </Router>
    </div>
   
  );
};

export default App;
