import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const FilmCard = ({ film }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const videoRef = useRef(null);

  const navigateToFilm = () => {
    navigate(`/realisation/${film.id}`);
  };

  const handleVideoClick = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) { /* Safari */
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) { /* IE11 */
      videoRef.current.msRequestFullscreen();
    }
  };

  const isVideo = (media) => {
    return media.endsWith('.mp4') || media.endsWith('.webm');
  };

  return (
    <div
      className="film-card"
      onClick={film.category === 'Bande Démo' ? handleVideoClick : navigateToFilm}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      
        <img
          src={process.env.PUBLIC_URL + film.imageBanner}
          alt={`${film.title} - Quentin Chantrel`}
          onLoad={() => setIsImageLoading(false)}
          className={isImageLoading ? 'loading' : ''}
        />
      
      <div className={`film-info ${isHovered ? 'hovered' : ''}`}>
        <h3>{film.title}</h3>
        <p>{film.year}</p>
      </div>
    </div>
  );
};

export default FilmCard;
