import React, { useState, useEffect } from 'react';
import FilmCard from './FilmCard';
import filmsData from '../data.json';
import Masonry from 'react-masonry-css';

const FilmGallery = () => {
  const [films, setFilms] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Tout');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setFilms(filmsData);
    const extractedCategories = ['Tout', ...new Set(filmsData.map(film => film.category))];
    setCategories(extractedCategories);
  }, []);

  const filterFilms = (category) => {
    setActiveCategory(category);
  };

  const filteredFilms = activeCategory === 'Tout' ? films : films.filter(film => film.category === activeCategory);

  const breakpointColumnsObj = {
    default: 4,
    1100: 4,
    700: 3,
    500: 2
  };

  return (
    <section className='section'>
    
        <div className="categories">
        {categories.map((category, index) => (
          <button
            key={index}
            className={activeCategory === category ? 'active' : ''}
            onClick={() => filterFilms(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="film-gallery"
        columnClassName="film-gallery-column">
        
        {filteredFilms.map(film => (
          <div key={film.id} className="film-card">
            <FilmCard film={film} />
          </div>
        ))}
      </Masonry>
    </section>
  );
};

export default FilmGallery;
