// PageNotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>404</h1>
      <p>Page non trouvée</p>
      <Link to="/">Retourner à l'accueil</Link>
    </div>
  );
};

export default PageNotFound;
