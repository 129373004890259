import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <a href='/' className="logo">
        <img src="/logo.png" alt="Logo" height="100" width="100" />
      </a>
      <nav className="navigation">
        <NavLink to="/bande-demo" exexact="true" activeClassName="active">BANDE DÉMO</NavLink>
        <NavLink to="/a-propos" activeClassName="active">À PROPOS</NavLink>
        <NavLink to="/contact" activeClassName="active">CONTACT</NavLink>
      </nav>
    </header>
  );
};

export default Header;
