import React from 'react'

export default function Demo() {
  return (
    <div className="demo-reel-card">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/_3nilOwVdZ8?si=ZNYKVyRyXvui95KC&start=11&autoplay=1&mute=1"
            title="Bande démo"
            frameBorder="0"
            allow="accelerometer; autoplay; loop, clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
  )
}
