

export default function Cv() {
  return (
    <section className="section container">
    <div>
    <h2><strong>RÉALISATEUR</strong></h2>
    <p>2022 – <strong>Petit frère</strong> – court-métrage produit par le Collectif Lova Lova – Guyane</p>
    <p>2021 – <strong>Apocalove</strong> – court-métrage produit par le Collectif Lova Lova – Guyane</p>
    <p>2020 – <strong>Ici c’est Paris</strong> – court-métrage produit par Palaviré Productions – Guyane</p>
    <p>2017 – <strong>Je suis un vélo</strong> – court-métrage produit par le Collectif Lova Lova – Guyane</p>
    <p>2017 – <strong>Badboy de Nikko</strong> – Clip – Guyane</p>
    <p>2015 – <strong>Résidence secondaire</strong> – court-métrage produit par le GREC – Corse</p>

    <h2><strong>CHEF-OPÉRATEUR</strong></h2>
    <p>2021 – <strong>Petit frère</strong> – court-métrage produit par le Collectif Lova Lova – Guyane</p>
    <p>2020 – <strong>Tètèche de Hugo Rousselin</strong> – court-métrage – Artisans du film – Paris</p>
    <p>2020 – <strong>S’aimer en Guyane</strong> – Documentaire 52’ – Bérénice Médias Corp – Guyane</p>
    <p>2020 – <strong>Parce que je t’aime</strong> – court-métrage produit par Orixa Films – Guyane</p>
    <p>2018 – <strong>Ici c’est Paris</strong> – court-métrage produit par Palaviré Productions – Guyane</p>
    <p>2017 – <strong>Bella nada</strong> – Clip de MRC produit par Justin Records – Saint Raphaël (France)</p>
    <p>2017 – <strong>Faut cesser</strong> – Clip de Amadeous produit par G Factory – Guyane</p>


    <h2><strong>PHOTOGRAPHE</strong></h2>
    <p>2022 – <strong>Photographie pour l’affiche du festival JOGGING 2022 / Carreau du Temple à Paris</strong> – Collectif LOVA LOVA</p>
    <p>2021 – <strong>Photographie pour l’affiche du festival de Marseille 2022</strong> – Collectif LOVA LOVA</p>
    <p>2021 – <strong>Photographie pour l’affiche du festival EVERYBODY / Carreau du Temple à Paris</strong> – Collectif LOVA LOVA</p>
    <p>2021 – <strong>Photographie pour l’affiche du festival JOGING / Carreau du Temple à Paris</strong> – Collectif LOVA LOVA</p>
    <p>2021 – <strong>Photographie pour l’affiche du festival de Marseille</strong> – Collectif LOVA LOVA</p>
    <p>2020 – <strong>Exposition de photographie au musée Bargoin et dans la ville de Clermont-Ferrand</strong> – Collectif LOVA LOVA</p>
    <p>2019 – <strong>Photographie pour l’affiche du festival de Marseille</strong> – Collectif LOVA LOVA</p>
    <p>2018 – <strong>Résidence artistique avec Léa Magnien</strong> – Collectif LOVA LOVA – OAXACA (Mexique)</p>
    <p>2018 – <strong>Exposition de photographies à Marseille 3013</strong> – Collectif LOVA LOVA – Marseille</p>
    <p>2018 – <strong>Exposition de photographies au Festival L’avide Jardin</strong> – Collectif LOVA LOVA – Alsace</p>
    <p>2018 – <strong>Résidence artistique avec Léa Magnien</strong> – Collectif LOVA LOVA – Belém (Brésil)</p>

    <h2><strong>CHEF-MACHINISTE</strong></h2>
    <p>2021 – <strong>Bel ti Koté</strong> – Série 4×13’’ – France TV – KANOPE FILMS</p>
    <p>2019 – <strong>Meurtres à Cayenne</strong> – Téléfilm 90’’ réalisé par Marc Barrat – ELOA PROD</p>
    <p>2017 – <strong>Kid Francescoli</strong> – Clip réalisé par Hawaii & smith – HVH Films – Marseille</p>
    <p>2017 – <strong>BA F1</strong> – Publicité réalisée par Christophe Mentz – Canal+ – Marseille</p>
    <p>2017 – <strong>Philipp Morris</strong> – Publicité réalisée par Hawaii & smith – Havas Productions –Marseille</p>
    <p>2016 – <strong>Grands sont les déserts</strong>– Court-métrage réalisé par Léna Renard – Le GREC – Corse</p>
    <p>2016 – <strong>Fallin’</strong> – Court-métrage de fiction réalisé par Alain Cucchi – Le GREC – Corse</p>
    <p>2016 – <strong>Interprétations</strong> – Court-métrage réalisé par Cédric Martin – Le GREC – Corse</p>
    <p>2016 – <strong>Maison du monde</strong> – Publicité de Bruno Delouzillière – Havas Productions – Marseille</p>
    <p>2015 – <strong>Toutes les couleurs de la nuit</strong> – C-M réalisé par Eléonore Berube – Le GREC – Corse</p>


    <h2><strong>MACHINISTE</strong></h2>
    <p>2017 / 2018 – <strong>Guyane Saison 2</strong> – Série créations originales CANAL+ réalisée par Jérôme Cornuau / Julien Despaux & Olivier Panchot – Endemol Shine</p>
    <p>2016 – <strong>Maroni, les fantômes du fleuve</strong> – Série ARTE réalisés par Olivier ABBOU – Lovemytv</p>
    <p>2015 – <strong>Guyane Saison 1 (épisode 5 à 8)</strong> – Série création originale CANAL + réalisée par philippe Triboit & Fabien Nury – Mascaret Films</p>


    <h2><strong>ATELIERS</strong></h2>
    <p>2021  – <strong>Atelier à la MFR de Régina</strong> – Film Circuit court – Régina</p>
    <p>2021  – <strong>Atelier cinéma au Collège Albert Londres</strong> – St. du Maroni (AVM)</p>
    <p>2020 – <strong>Atelier Dispositif ECLA (O. Sagne)</strong> – Courts-métrages – Cayenne</p>
    <p>2019  – <strong>Atelier à la MFR de Régina</strong> – Film 10 ans de la MFR – Régina</p>
    <p>2017  – <strong>Atelier à la MFR de Régina</strong> – Film Cacao d’Amazonie – Régina</p>
    <p>2016  – <strong>Atelier à la MFR de Régina</strong> – Parole d’habitants – Régina</p>


    <h2><strong>FORMATION</strong></h2>
    <p>2020 – <strong>Atelier d’écriture Doc Amazonie Caraïbes</strong> – Guyane</p>
    <p>2019  – <strong>Brevet d’aptitude aux fonctions de télépilote de drone pour les scénarios S1, S2 & S3</strong></p>
    <p>2019  – <strong>Atelier d’écriture Doc Amazonie Caraïbes</strong> – Guyane</p>
    <p>2019  – <strong>Le chef-opérateur : La préparation d’une fiction et pratique en simulation 3D (Formation Afdas)</strong></p>
    <p>2014  – <strong>DU « Créations & Techniques Audiovisuelles & cinématographiques de Corse »</strong></p>
    <p>2013  – <strong>Atelier d’écriture « Réécrire, préparer et penser son film » en Guyane.</strong></p>
    <p>2011   – <strong>Licence de psychologie à l’université François Rabelais (Tours).</strong></p>
    <p>2007 – <strong>Baccalauréat Scientifique au lycée Félix Eboué – Guyane</strong></p>


    <h2><strong>DIVERS</strong></h2>
    <p><strong>Membre de Guyane Art Factory depuis 2020</strong></p>
    <p><strong>Membre de l’association de photographies Collectif LOVA LOVA depuis 2017</strong></p>
    <p><strong>Membre du CA de l’association G-CAM (Guyane – Cinéma Audiovisuel et Multimédia) depuis 2013</strong></p>
    </div>
</section>
  )
}
