import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
    <p>
      ©Quentin Chantrel tous droits réservés | Dev <a rel="noreferrer" target='_blank' href='https://www.kabatis.com'>Kabatis Web</a>
    </p>
    </div>
  )
}
