import React from 'react'

export default function Contact() {
  return (
    <section className='contact'>
      <h1>Contact</h1>
      <a href='emailto:quentinchantrel@gmail.com'>quentinchantrel@gmail.com</a>
    </section>
  )
}
