import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import filmsData from '../data.json';

// SVG contents
const arrowBackSvg = `
<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#FFF"><path d="M400-107.69 27.69-480 400-852.31l42.54 42.54L112.77-480l329.77 329.77L400-107.69Z"/></svg>
`;

const arrowForwardSvg = `
<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#FFF"><path d="m320.23-107.69-42.54-42.54L607.46-480 277.69-809.77l42.54-42.54L692.54-480 320.23-107.69Z"/></svg>
`;

const closeSvg = `
<svg xmlns="http://www.w3.org/2000/svg"  height="34px" viewBox="0 -960 960 960" width="34px" fill="#FFF"><path d="M256-227.69 227.69-256l224-224-224-224L256-732.31l224 224 224-224L732.31-704l-224 224 224 224L704-227.69l-224-224-224 224Z"/></svg>
`;

Modal.setAppElement('#root'); 

const FilmDetails = () => {
  let { id } = useParams();
  const [film, setFilm] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const filmData = filmsData.find(f => f.id.toString() === id);
    setFilm(filmData);
  }, [id]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (modalIsOpen) {
        if (event.key === 'ArrowRight') {
          nextImage();
        } else if (event.key === 'ArrowLeft') {
          prevImage();
        } else if (event.key === 'Escape') {
          closeModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsOpen, currentImageIndex]);

  if (!film) {
    return <div>Film introuvable</div>;
  }

  const renderMedia = (media, index) => {
    if (media.endsWith('.mp4') || media.endsWith('.webm')) {
      return (
        <video key={index} controls className="gallery-item">
          <source src={process.env.PUBLIC_URL + media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img
          key={index}
          src={process.env.PUBLIC_URL + media}
          alt={`realisation-${index} - Quentin Chantrel`}
          className={`gallery-item ${isImageLoading ? 'loading' : ''}`}
          onLoad={() => setIsImageLoading(false)}
          onClick={() => openModal(index)}
        />
      );
    }
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % film.gallery.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + film.gallery.length) % film.gallery.length);
  };

  return (
    <section className="section film-details">
      <h1>{film.title}</h1>
      <div className="film-credits">
        <p><strong>{film.year}</strong></p>
        {film.director && <p>Réalisation: {film.director}</p>}
        {film.cinematographer && <p>Chef opérateur: {film.cinematographer}</p>}
        {film.photographes && <p>Photographes: {film.photographes}</p>}
        {film.production && <p>Production: {film.production}</p>}
        {film.cast && film.cast.length > 0 && <p>Avec: {film.cast.join(', ')}</p>}
      </div>
      <div className="gallery">
        {film.gallery.map((media, index) => renderMedia(media, index))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal} className="close-modal" dangerouslySetInnerHTML={{ __html: closeSvg }} />
        <button onClick={prevImage} className="prev-image" dangerouslySetInnerHTML={{ __html: arrowBackSvg }} />
        <img
          src={process.env.PUBLIC_URL + film.gallery[currentImageIndex]}
          alt={`Modal realisation-${currentImageIndex} - Quentin Chantrel`}
          className="modal-image"
        />
        <button onClick={nextImage} className="next-image" dangerouslySetInnerHTML={{ __html: arrowForwardSvg }} />
      </Modal>
    </section>
  );
};

export default FilmDetails;
